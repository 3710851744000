<template>
  <div class="book-detail">
    <p class="title">环境管理体系典型案例</p>
    <div>
      <p class="ptittle">书本介绍</p>
      <span
        >本书为企业建立环境管理体系的各个阶段和过程都提供了丰富经典案例，书中案例均出自笔者辅导企业的真实案例，包括广州本田、东风本田发动机、金霸王、ICI太古漆等知名企业。</span
      >
    </div>
    <br />
    <div>
      <p class="ptittle">内容介绍</p>
      <span
        >为了在国内大力宣传ISO14001标准，帮助企业建立环境管理体系，中国标准出版社与我司共同策划了一套《ISO14001理论与实践丛书》，本书是其中之一。</span
      >
      <br />
      <span>
        本书在环境管理体系所涉及的主要方面都给出了典型案例，包括环境方针、环境因素、目标和指标、环境管理方案、员工培训、体系文件等，所有提供案例的公司均为安索管理顾问有限公司的客户，其中包括了欧洲、美国、日本、台湾和香港在中国大陆投资的企业，也包括了国内著名企业，这些企业中不乏国际知名品牌。从这些企业获得ISO14001证书的情况看，认证机构所在的国家或地区分别是中国、英国、法国、德国、瑞士、挪威、香港等，可以认为有相当程度的代表性。
        不过，尽管将本书推荐给读者，但是其内容仅起参考作用——任何照办模式的办法都不可能建立起真正有效的环境管理体系。我们不否认ISO14001的作用，同时也反对将其作用无限扩大化的做法，近来媒体走火入魔式的夸大宣传不仅不能．帮助行业的发展，反而有损ISO14001本来的健康。全面认识ISO14001和它的作用并还其本来面目才是唯一正确的选择。这也是本书的目的所在。
      </span>
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style lang="less" scoped>
@import "../../../assets/style/BookStyle.css";
</style>